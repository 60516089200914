import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO title="404" description="Reino Muhl: Developer - 404 page not found"/>
    <div>
      <h1>Page Does Not Exist</h1>
      <h2>This is not the page you are looking for</h2>
      <p>Let's get you back <Link to="/">Home</Link>.</p>
    </div>
  </Layout>
)